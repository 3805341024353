.header {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.1), 
    0px 2px 4px rgba(0,0,0,0.1);

    &__topContainer {
        display: flex;
        flex-direction: row;

        gap: 1.25rem;
        white-space: nowrap;

        @media screen and (max-width: 930px) {
            ::-webkit-scrollbar {
                display: none;
              }
            overflow: scroll;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;
        }
    }

    &__shortNavigation {
        flex: 1;
        display: flex;
        column-gap: 1.25rem;

        a {
            color: black;
            font-size: 1rem;
            text-decoration: none;
        }
    }

    &__contacts {
        display: flex;
        flex-direction: row;
        column-gap: 1.25rem;
        color: black;
        height: fit-content;
    }

    &__logo {
        height: fit-content;
        min-width: 6rem;
        max-width: 6rem;
        // min-width: 6.25rem;
    }

    &__bottomContainer {
        display: flex;
        flex-direction: row;
        gap: 1.25rem;
        white-space: nowrap;

        @media screen and (max-width: 1100px) {
            ::-webkit-scrollbar {
                display: none;
              }
            overflow: scroll;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;
        }

        a {
            margin: auto 0;
            font-size: 1.25rem;
            font-weight: bold;
            color: black;
            text-decoration: underline;
        }
    }

    &__menuButton {
        margin: auto 0;
    }
}

.topHeader {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem 0 2rem;
    position: sticky;
    top: 0;
    row-gap: 1rem;
    background: white;
    z-index: 10;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.1), 
    0px 2px 4px rgba(0,0,0,0.1);

    &__topContainer {
        display: flex;
        flex-direction: row;

        gap: 1.25rem;
        white-space: nowrap;

        @media screen and (max-width: 930px) {
            ::-webkit-scrollbar {
                display: none;
              }
            overflow: scroll;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;
        }
    }
}

.iconText {
    display: flex;
    flex-direction: row;
    column-gap: 0.625rem;
    svg {
        margin: auto;
        height: 1rem;
    }
}

.divider {
    &_horizontal {
        width: 100%;
        height: 1px;
        background: black;
    }
}

.button {
    &_menu {
        padding: 0.5rem 1rem;
        background: black;
        border: 1px solid transparent;
    
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
        svg {
            margin: auto;
            height: 1.5rem;
            color: white; // TODO to svg
        }
        
        span {
            color: white;
            margin: auto 0;
            font-size: 1.5rem;
            font-weight: bold;
        }
    }
}