.awardsBlock {
    background: black;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        flex-direction: row;
        column-gap: 2.5rem;
    }

    &__divider {
        background: white;
        height: 3px;
        flex: 1;
        margin: auto 0;
    }

    &__title {
        color: white;
        font-size: 3rem;
        font-weight: 900;
    }

    &__grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 4rem;
    }

    &__card {
        flex: 1 0 30%;
    }
}
