.portfolioBlock {
    padding: 2.5rem;
    display: flex;
    flex-direction: row;
    gap: 1.25rem;

    &__sidebar {
        display: flex;
        flex: 0.4;

        @media screen and (max-width: 760px) {
            display: none;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__main {
        flex: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
    }

    &__title {
        font-weight: 900;
        font-size: 3.75rem;
        text-decoration: underline;
    }

    &__board {
        display: grid;
        grid-template-columns: 1fr 1fr;

        gap: 1.25rem;

        :nth-child(n) {
            flex: 1 0 45%;
            height: auto;
            width: 100%;
            aspect-ratio: 3 / 2;
        }
    }

    &__linkToPortfolioInner {
        display: flex;
        flex-direction: row;
        gap: 0.75rem;

        span {
            color: black;
            text-decoration: underline;
            font-size: 1rem;
        }
        svg {
            color: black;
            height: 1rem;
        }
    }

    &__linkIcon {
        height: 0.75rem;
        margin: auto 0;
    }
}