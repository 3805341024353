.leadSection {
    padding: 2.5rem;
    background: black;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2.5rem;

    &__sidebar {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        @media screen and (max-width: 760px) {
            display: none;
        }
    }

    &__sidebarTitle {
        color: white;
        margin: 0 auto;
        font-size: 3rem;
        transform: rotate(-180deg);
        font-weight: 900;
        writing-mode: vertical-rl,;
        text-orientation: mixed;
    }

    &__divider {
        flex: 1;
        width: 3px;
        margin: 0 auto;
        background: white;
    }

    &__sidebarSubtitle {
        color: white;
        margin: 0 auto;
        font-size: 1.5rem;
        transform: rotate(-180deg);
        font-weight: light;
        writing-mode: vertical-rl,;
        text-orientation: mixed;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__imageWrapper {
        flex: 1;

        :nth-child(1) {
            width: 100%;
            height: 100%;
        }

        @media screen and (max-width: 760px) {
            display: none;
        }
    }

    &__leadForm {
        flex: 1;
    }
}
