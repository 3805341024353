.leadForm {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    &__title {
        color: white;
        font-size: 4rem;
        font-weight: 900;
    }

    &__subtitle {
        color: white;
        font-size: 1.5rem;
    }

    &__controls {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
        flex: 1;
    }

    &__submitButton {
        background: #E74C3C;
        border: 1px solid #E74C3C;
        padding: 1rem;

        span {
            color: white;
            font-weight: 900;
            font-size: 2rem;
        }
    }

    &__divider {
        display: flex;
        flex-direction: row;
        gap: 1.25rem;

        div {
            flex: 1;
            background: white;
            height: 1px;
            margin: auto 0;
        }

        span {
            color: white;
            font-size: 1.5rem;
        }
    }

    &__textArea {
        flex: 1;
    }

    &__pdfLink {
        color: white;
        text-decoration: underline;
        font-weight: bold;
        font-size: 1.5rem;
        margin: 0 auto;
        text-align: center;
    }
}