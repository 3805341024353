.fAQsBlock {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding: 2.5rem;

    &__title {
        font-size: 3.75rem;
        font-weight: 900;
        text-decoration: underline;
        margin: 0 auto;
    }

    &__main {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
    }
}

.fAQCard {
    display: flex;
    flex-direction: column;

    &__question {
        display: flex;
        flex-direction: row;
        padding: 1.25rem;

        border: 1px solid black;
        background: white;
        z-index: 1;
        cursor: pointer;

        span {
            font-size: 1.5rem;
            font-weight: bold;
            flex: 1;
        }
    }

    &__answerWrapper {
        padding: 1.25rem;
        text-align: justify;
        line-height: 1.5;
        overflow: hidden;
        &_close {
            animation: close 0.5s forwards;
        }

        &_open {
            animation: open 0.5s forwards;
        }
    }

    &__icon {
        height: 1.5rem;
        &_close {
            animation: icon_close 0.5s forwards;
        }

        &_open {
            animation: icon_open 0.5s forwards;
        }
    }
}

@keyframes close {
    0% {
        transform: translateY(0);
    }
    100% {
        visibility: hidden;
        height: 0;
        padding: 0 1.25rem;
        overflow: hidden;
        display: none;
    }
}

@keyframes open {
    0% {
        transform: translateY(-100%);
        padding: 0 1.25rem;
        overflow: hidden;
        display: block;
        height: 0;
    }
    50% {
        transform: translateY(0%);
    }
    100% {
        height: auto;
    }
}

@keyframes icon_open {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(90deg);
    }
}

@keyframes icon_close {
    0% {
        transform: rotate(90deg);
    }
    100% {
        transform: rotate(0deg);
    }
}