.crewMembersBlock {
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    position: relative;

    &__header {
        z-index: 1;
        display: flex;
        gap: 2.5rem;
        span {
            font-size: 3rem;
            text-decoration: underline;
            font-weight: 900;
        }
    }

    &__section {
        display: flex;
        flex-direction: row;
        gap: 2.5rem;

        @media screen and (max-width: 1200px) {
            flex-direction: column;
        }
    }

    &__crewMemberInfoBlock {
        // margin: auto 0;
        display: flex;
        flex-direction: column;
        flex: 0.4;

        p {
            text-align: justify;
            line-height: 1.5;
        }

        @media screen and (max-width: 1200px) {
            text-align: center;

            p {
                text-align: center;
            }
        }
    }

    &__crewMemberName {
        font-size: 2.25rem;
        font-weight: bold;
    }

    &__crewMemberJobTitle {
        font-size: 1.25rem;
    }

    &__carousel {
        flex: 1;
    }

    &__buttonsWrapper {
        display: flex;
        flex-direction: row;
        gap: 1.25rem;
        width: fit-content;
    }

    &__backgroundBox {
        background: #E74C3C;
        position: absolute;
        left: 2.5rem;
        top: 2.5rem;
        height: 8.125rem;
        width: 8.125rem;
    }

    &__divider {
        height: 3px;
        background: black;
        flex: 1;
        margin: auto 0;
    }
}

.crewMemberCard {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    &__image {
        aspect-ratio: 3 / 5;
        object-fit: cover;
        width: 100%;
    }
}