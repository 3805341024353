.navigationBlock {
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        flex-direction: row;
        column-gap: 2.5rem;
    }

    &__main {
        display: flex;
        flex-direction: column;
        padding: 2.5rem;
        gap: 2.5rem;
    }

    &__title {
        font-size: 3.75rem;
        text-decoration: underline;
        font-weight: 900;
        color: black;
    }

    &__cateringSection {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;

        h2 {
            padding: 0;
            margin: 0;
            font-size: 3.75rem;
            text-decoration: underline;
            font-weight: 900;
            
            a {
                color: black;
            }
        }

        div {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 1.25rem;

            div {
                flex: 1 0 23%;
                display: flex;
                flex-direction: column;
                gap: 1.25rem;

                a {
                    font-size: 2rem;
                    font-weight: bold;
                    color: black;
                }

                ul {
                    padding: 0;
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 0.6125rem;

                    li {
                        a {
                            color: black;
                            font-size: 1.5rem;
                            text-decoration: none;
                            font-weight: normal;
                        }
                    }
                }
            }
        }
    }

    &__sweetShopSection {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;

        h2 {
            padding: 0;
            margin: 0;
            font-size: 3.75rem;
            text-decoration: underline;
            font-weight: 900;
            
            a {
                color: black;
            }
        }

        div {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 2.5rem;

            a {
                color: black;
                font-size: 1.5rem;
                text-decoration: none;
            }
        }
    }

    &__deliverySection {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;

        h2 {
            padding: 0;
            margin: 0;
            font-size: 3.75rem;
            text-decoration: underline;
            font-weight: 900;
            
            a {
                color: black;
            }
        }

        div {
            display: flex;
            flex-direction: row;
            gap: 2.5rem;

            ul {
                padding: 0;
                margin: 0;
                display: flex;
                flex-direction: column;
                gap: 0.6125rem;

                li {
                    a {
                        color: black;
                        font-size: 1.5rem;
                        text-decoration: none;
                    }
                }
            }

            div {
                display: flex;
                flex-direction: column;
                gap: 1.25rem;
                flex: 1;

                span {
                    font-size: 2rem;
                    font-weight: bold;
                    color: black;
                }

                div {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 1.25rem;
                    
                    a {
                        flex: 1 0 30%;
                        color: black;
                        font-size: 1.5rem;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &__culinaryStudioSection {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;

        h2 {
            padding: 0;
            margin: 0;
            font-size: 3.75rem;
            text-decoration: underline;
            font-weight: 900;
            
            a {
                color: black;
            }
        }

        div {
            display: flex;
            flex-direction: row;
            gap: 1.25rem;

            div {
                flex: 1 0 45%;
                display: flex;
                flex-direction: column;
                gap: 1.25rem;

                a {
                    font-size: 2rem;
                    font-weight: bold;
                    color: black;
                }

                div {
                    div {
                        display: flex;
                        flex-direction: row;
                        gap: 1.25rem;
                        flex-wrap: wrap;
                        a {
                            flex: 1 0 30%;
                            color: black;
                            font-size: 1.5rem;
                            text-decoration: none;
                            font-weight: normal;
                        }
                    }
                }
            }
        }
    }

    &__outdoorBarsSection {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;

        h2 {
            padding: 0;
            margin: 0;
            font-size: 3.75rem;
            text-decoration: underline;
            font-weight: 900;
            
            a {
                color: black;
            }
        }

        div {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 2.5rem;

            a {
                color: black;
                font-size: 1.5rem;
                text-decoration: none;
            }
        }
    }
}
