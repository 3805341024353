.awardCard {
    display: flex;
    flex-direction: row;
    column-gap: 1.25rem;

    &__yearsList {
        padding: 0;
        li {
            color: #E74C3C;
            font-size: 1.75rem;
            font-weight: 900;
        }
    }

    div {
        p {
            flex: 1;
        }
        display: flex;
        flex-direction: column;
        color: white;
        text-align: justify;
    }

    &__image {
        aspect-ratio: 1 / 1;
        width: 100%;
    }
}