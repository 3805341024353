.imagesBlock {
    padding: 2.5rem 0;
    display: flex;
    flex-direction: row;
    gap: 1.25rem;
    ::-webkit-scrollbar {
        display: none;
      }
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    aspect-ratio: 6 / 2;

    &__imageWrapper {
        display: flex;
        flex: 1 0 33%;
        cursor: pointer;
    }

    &__image {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.collage {
    display: flex;
    flex-direction: row;
    gap: 1.25rem;
    position: relative;

    &__image1 {
        flex: 1;
        position: relative;
        :nth-child(1) {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    &__image2 {
        flex: 1;
        position: relative;
        :nth-child(1) {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__image3 {
        flex: 1;
        position: relative;
        :nth-child(1) {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__imageContainer {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
        flex: 1;
    }
}