.pageLayout {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    background: white;
    margin: 0 auto;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.1), 
    0px 2px 4px rgba(0,0,0,0.1), 
    0px 4px 8px rgba(0,0,0,0.1), 
    0px 8px 16px rgba(0,0,0,0.1);
}