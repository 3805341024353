.image {
    display: block;

    &_hidden {
        display: none;
    }
}

.imageLoader{
    background: linear-gradient(90deg, #dddddd00, #dddddd7a, #dddddd00);
	background-size: 400% 400%;
	animation: gradient 3s ease infinite;
}


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}