.textArea {
    padding: 1.25rem;
    font-size: 1.5rem;
    resize: vertical;
    
    &_light {
        border: 2px solid white;
        color: white;
        background: transparent;
    }
}