.articleCard {
    display: flex;
    flex-direction: column;
    position: relative;

    &__image {
        aspect-ratio: 1 / 1;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &__weightPriceContainer {
        display: flex;
        gap: 1.25rem;
    }

    &__weight {
        flex: 1;
        margin: auto 0;
        font-size: 1rem;
    }

    &__price {
        font-size: 2rem;
    }

    &__name {
        font-size: 1.5rem;
        font-weight: bold;
        text-decoration: underline;
        color: black;
    }
}