.contactsBlock {
    display: flex;
    flex-direction: row;

    background: black;
    padding: 2.5rem;
    gap: 2.5rem;

    @media screen and (max-width: 760px) {
        flex-direction: column;
    }

    &__leadForm {
        flex: 1;
    }

    &__divider {
        background: white;
        width: 3px;

        @media screen and (max-width: 760px) {
            width: auto;
            height: 3px;
        }
    }

    &__infoSection {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
    }

    &__title {
        color: white;
        font-size: 3rem;
        font-weight: 900;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 4rem;
    }

    &__contacts {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;

    }

    &__socialMedias {
        display: flex;
        flex-direction: row;
        gap: 1.25rem;

        a {
            color: white;
            svg {
                height: 3.3rem;
                width: auto;
            } 
        }
    }

    &__address {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;

    }

    &__map {
        display: flex;
        width: 100%;
        aspect-ratio: 3 / 2;
    }

    &__mapWrapper {
        flex: 1;
        display: flex;
        flex-direction: row;
        gap: 1.25rem;
    }

    &__mapDivider {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;

        @media screen and (max-width: 1000px) {
            display: none;
        }

        div {
            margin: 0 auto;
            flex: 1;
            background: white;
            width: 3px;
        }

        span {
            color: white;
            margin: 0 auto;
            font-size: 2rem;
            font-weight: bold;
            writing-mode: vertical-rl,;
            text-orientation: mixed;
        }
    }
}

.iconText {
    display: flex;
    flex-direction: row;
    column-gap: 0.625rem;

    svg {
        margin: auto 0;
        height: 1.5rem;
        width: auto;
        color: white;
    }

    span {
        font-size: 1.5rem;
        color: white;
    }
}