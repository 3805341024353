.tabs {
    &_small {
        display:  flex;
        flex-direction: column;
        gap: 1.25rem;

        &__header {
            display:  flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 1.25rem;
        }

        &__item {
            font-size: 1rem;
            cursor: pointer;

            &_selected {
                text-decoration: underline;
                font-weight: bold;
            }
        }

        &__main {
            display: flex;
            flex-direction: column;
        }
    }

    &_regular {
        display:  flex;
        flex-direction: column;
        gap: 2.5rem;

        &__header {
            display:  flex;
            flex-direction: row;
            flex-wrap: nowrap;
            padding: 0 2.5rem;
            gap: 2.5rem;
            overflow: hidden;

            @media screen and (max-width: 930px) {
                ::-webkit-scrollbar {
                    display: none;
                  }
                overflow: scroll;
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;
            }
        }

        &__item {
            white-space: nowrap;
            font-size: 1.5rem;
            cursor: pointer;
            border: 3px solid transparent;
            padding: 0 1rem;
            height: fit-content;
            font-weight: bold;

            &_selected {
                border: 3px solid #E74C3C;
                padding: 0 1rem;
                height: fit-content;
            }
        }

        &__main {
            display: flex;
            flex-direction: column;
        }
    }
}
