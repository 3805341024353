body {
  background: #D9D9D9;
  margin: 0;
  font-family: 'Circe', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: 16px;
}

h1 h2 h3 h4 h5 h6 p ul li {
  padding: 0;
  margin: 0;
}

ul li {
  list-style: none;
}

@font-face {
  font-family: "Circe";
  src: url("../public/fonts/circe//circe.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "Circe";
  src: url("../public/fonts/circe/circe_light.ttf");
  font-weight: lighter;
}
@font-face {
  font-family: "Circe";
  src: url("../public/fonts/circe/circe_bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "Circe";
  src: url("../public/fonts/circe/circe_extrabold.ttf");
  font-weight: 900;
}

html {
  font-size: 16px;
  overflow-x: hidden;
}

@media screen and (max-width: 450px) {
  html {
    font-size: 10px;
  }
}

@media screen and (max-width: 300px) {
  html {
    font-size: 6px;
  }
}