.degustationBlock {
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        flex-direction: row;
        column-gap: 2.5rem;
    }

    &__divider {
        background: white;
        height: 3px;
        flex: 1;
        margin: auto 0;
    }

    &__title {
        color: white;
        font-size: 3rem;
        font-weight: 900;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 4rem;
    }
}
